import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import '../../styles/common.css';
import ReactPaginate from 'react-paginate';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  dateFormat,
  baseUri,
  showLoader,
  hideLoader,
  authCheck,
  appendErrorMsg,
  refreshPage,
  buildQuery,
  ADMIN,
  checkNumber,
  getPageAccessFromState,
  measures,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import toast, { Toaster } from 'react-hot-toast';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  margin-bottom: 10px;
  padding: 4px;
`;
const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const initialIteams = {
  item: '',
  defaultItem: [],
  orderedQty: '',
  acceptedQty: '',
  rejectedQty: '',
  ratePerQty: '',
  tax: '',
  cgst: '',
  sgst: '',
  igst: '',
  total: '',
  po: '',
  itemStock: '',
};

const graItemCategoyOptions = [
  { value: '1', label: '1 - Regular GRA', limit: '1,4000' },
  { value: '2', label: '2 - OGRA', limit: '4001,7000' },
  { value: '3', label: '3 - OGRA Service', limit: '7001,10000' },
];

const graItemGroupOptions = [
  { value: '1', label: '1 - General Factory Expenses' },
  { value: '2', label: '2 - Electrical Maintenance' },
  { value: '3', label: '3 - Machinery Maintenance' },
  { value: '4', label: '4 - Printing and Stationaries' },
  { value: '5', label: '5 - Packing Material' },
  { value: '6', label: '6 - Van Maintenance Expenses' },
  { value: '7', label: '7 - Car Maintenance Expenses' },
  { value: '8', label: '8 - Building Maintenance' },
  { value: '9', label: '9 - Capital Account' },
  { value: '0', label: '0 - Others' },
];

const initialFormInput = {
  graDate: dateFormat(new Date().toISOString()),
  InPassDate: dateFormat(new Date().toISOString()),
  InPassNumber: '',
  TCS_Amt: '0',
  TCS_PCT: '0',
  tcsPctDisable: false,
  TDS_Amt: '0',
  TDS_PCT: '0',
  tdsPctDisabled: false,
  graItemCat: '',
  defaultGraItemCat: [],
  graItemCategoryOptions: graItemCategoyOptions,
  graItemNum: '',
  graItemNumDefault: [],
  graItemNumOptions: [],
  graItemGrp: '',
  graItemGrpDefault: [],
  graItemGroupOptions: graItemGroupOptions,
  remarks: '',
  supplier: '',
  decideGST: true,
  invoiceNumber: 'TBE',
  invoiceDate: dateFormat(new Date().toISOString()),
  transportName: '',
  vechileNumber: '',
  cgstTotal: '',
  igstTotal: '',
  sgstTotal: '',
  grandTotal: '',
  IteamGRA: [],
  accountVouchNum: '',
  accountDate: dateFormat(new Date().toISOString()),
};

const StoreItemGra = () => {
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [formInput, setFormInput] = useState(() => initialFormInput);
  const [btnDisable, setBtnDisable] = useState(false);
  const [pickId, setPickId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);

  const [defaultSupplier, setDefaultSupplier] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);

  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);

  const [oldItems, setoldItems] = useState([]);
  const [showErr, setShowErr] = useState('');

  const authState = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(authState);
  const [isSave, setSave] = useState(pageAccess.write === 1);

  const [defaultDecideGST, setDefaultDecideGST] = useState({ label: 'CGST/SGST', value: true });
  const decideGSTOptions = [
    { label: 'CGST/SGST', value: true },
    { label: 'IGST', value: false },
  ];

  const measureArray = measures();

  useEffect(() => {
    authCheck(authState.authData);
  }, []);

  useEffect(() => {
    getApi();
  }, [page]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    if (showErr.length) {
      setTimeout(() => {
        setShowErr('');
      }, 3000);
    }
  }, [showErr]);

  // useEffect(() => {
  //   calculateTotal();
  // }, [formInput.IteamGRA, formInput.TCS_PCT, formInput.TDS_PCT]);

  const graItemCategoryChange = (event) => {
    setFormInput({
      ...formInput,
      graItemNum: '',
      graItemCat: event.value,
      defaultGraItemCat: { label: `${event.label}`, value: event.value },
      graItemNumDefault: [],
    });
    createGraItemOptions(event);
    pullDownReset('graItemCat');
  };

  const createGraItemOptions = (event, edit = 0, ref) => {
    let limit = event.limit.split(',');
    let options = [];
    for (let i = +limit[0]; i <= +limit[1]; i++) {
      options.push({ label: i, value: i });
    }
    let item = edit > 0 ? edit : '';
    let defaultVal = edit > 0 ? { label: edit, value: edit } : [];
    if (edit > 0) {
      ref.graItemNumOptions = options;
      ref.graItemNum = item;
      ref.graItemNumDefault = defaultVal;
    } else {
      setFormInput((prevState) => ({
        ...prevState,
        graItemNumOptions: options,
        graItemNum: item,
        graItemNumDefault: defaultVal,
      }));
    }
  };

  const graItemNumChange = (event) => {
    setFormInput({
      ...formInput,
      invoiceNumber: '',
      graItemNum: event.value,
      graItemNumDefault: { label: event.label, value: event.value },
    });
    pullDownReset('graItemNum');
  };

  const graItemGroupChange = (event) => {
    setFormInput({
      ...formInput,
      graItemGrp: event.value,
      graItemGrpDefault: { label: event.label, value: event.value },
    });
    pullDownReset('graItemGrp');
  };

  const AddItem = () => {
    let initialItems = { ...initialIteams };
    let newItem = [...formInput.IteamGRA, { ...initialItems }];
    setFormInput({ ...formInput, IteamGRA: newItem });
  };

  const handleChange = (e) => {
    resetRequired(e);
    const { name, value } = e.target;
    let final = value;

    let tdsPctDisabled = formInput.tdsPctDisabled;
    let tcsPctDisabled = formInput.tcsPctDisabled;
    if (name == 'TCS_PCT') {
      tdsPctDisabled = value ? true : false;
      tcsPctDisabled = false;
      final = checkNumber(e.target, '2.2');
    } else if (name == 'TDS_PCT') {
      tdsPctDisabled = false;
      tcsPctDisabled = value ? true : false;
      final = checkNumber(e.target, '2.2');
    } else if (name == 'TDS_Amt' || name == 'TCS_Amt') {
      final = checkNumber(e.target, '5.2');
    }
    setFormInput({
      ...formInput,
      [name]: final,
      tcsPctDisabled: tcsPctDisabled,
      tdsPctDisabled: tdsPctDisabled,
    });
    if (name === 'IteamGRA' || name === 'TCS_PCT' || name === 'TDS_PCT') {
      calculateTotal({
        ...formInput,
        [name]: final,
        tcsPctDisabled: tcsPctDisabled,
        tdsPctDisabled: tdsPctDisabled,
      });
    }
  };

  const supplierChange = (event) => {
    setFormInput({ ...formInput, supplier: event.value });
    setDefaultSupplier({ label: event.label, value: event.value });
    pullDownReset('supplier');
  };

  const decideGSTChange = (event) => {
    setFormInput({ ...formInput, decideGST: event.value });
    setDefaultDecideGST({ label: event.label, value: event.value });
  };

  const itemInputChange = (e, index) => {
    resetRequired(e);
    const { name, value } = e.target;
    formInput.IteamGRA[index][name] = value;
    calculateRate(index);
    setFormInput({ ...formInput, IteamGRA: formInput.IteamGRA });
  };

  const calculateRate = (index) => {
    let orderedQty = Number(formInput.IteamGRA[index].orderedQty);
    let acceptedQty = Number(formInput.IteamGRA[index].acceptedQty);
    let ratePerQty = Number(formInput.IteamGRA[index].ratePerQty);
    let tax = Number(formInput.IteamGRA[index].tax);
    let rejectedQty = '';
    if (acceptedQty > orderedQty) {
      let temp = String(acceptedQty);
      formInput.IteamGRA[index].acceptedQty = temp.slice(0, -1);
    }
    if (orderedQty > 0 && acceptedQty > 0) {
      rejectedQty = orderedQty - acceptedQty;
      formInput.IteamGRA[index].rejectedQty = parseFloat(rejectedQty).toFixed(2);
      if (ratePerQty > 0 && tax >= 0) {
        let totalRate = parseFloat(ratePerQty * acceptedQty);
        let taxAmount = 0;
        if (tax > 0) {
          taxAmount = parseFloat(totalRate * tax) / 100;
          if (formInput.decideGST) {
            // cgst
            formInput.IteamGRA[index].cgst = parseFloat(taxAmount / 2).toFixed(2);
            formInput.IteamGRA[index].sgst = parseFloat(taxAmount / 2).toFixed(2);
            formInput.IteamGRA[index].igst = 0;
          } else {
            // igst
            formInput.IteamGRA[index].igst = parseFloat(taxAmount).toFixed(2);
            formInput.IteamGRA[index].cgst = 0;
            formInput.IteamGRA[index].sgst = 0;
          }
        } else {
          formInput.IteamGRA[index].cgst = 0;
          formInput.IteamGRA[index].sgst = 0;
          formInput.IteamGRA[index].igst = 0;
        }
        formInput.IteamGRA[index].total = parseFloat(totalRate + taxAmount).toFixed(2);
      } else {
        formInput.IteamGRA[index].cgst = 0;
        formInput.IteamGRA[index].sgst = 0;
        formInput.IteamGRA[index].igst = 0;
        formInput.IteamGRA[index].total = 0;
      }
    } else {
      formInput.IteamGRA[index].rejectedQty = 0;
    }
    calculateTotal();
  };

  const calculateTotal = (currFormInput = formInput) => {
    var val = {};
    val.cgstTotal = currFormInput.IteamGRA.reduce((a, { cgst }) => a + Number(cgst), 0);
    val.sgstTotal = currFormInput.IteamGRA.reduce((a, { sgst }) => a + Number(sgst), 0);
    val.igstTotal = currFormInput.IteamGRA.reduce((a, { igst }) => a + Number(igst), 0);
    val.grandTotal = currFormInput.IteamGRA.reduce((a, { total }) => a + Number(total), 0);
    val.totalRate = currFormInput.IteamGRA.reduce((a, { ratePerQty, acceptedQty }) => a + ratePerQty * acceptedQty, 0);

    currFormInput.cgstTotal = val.cgstTotal >= 0 ? parseFloat(val.cgstTotal).toFixed(2) : 0;
    currFormInput.sgstTotal = val.sgstTotal >= 0 ? parseFloat(val.sgstTotal).toFixed(2) : 0;
    currFormInput.igstTotal = val.igstTotal >= 0 ? parseFloat(val.igstTotal).toFixed(2) : 0;
    currFormInput.grandTotal = val.grandTotal >= 0 ? parseFloat(val.grandTotal).toFixed(2) : 0;

    if (currFormInput.TCS_PCT > 0) {
      currFormInput.TCS_Amt = (val.totalRate * (currFormInput.TCS_PCT / 100)).toFixed(2);
      currFormInput.TDS_PCT = '';
    } else if (currFormInput.TDS_PCT > 0) {
      currFormInput.TDS_Amt = (val.totalRate * (currFormInput.TDS_PCT / 100)).toFixed(2);
      currFormInput.TCS_PCT = '';
    } else if (currFormInput.TCS_PCT == '' && currFormInput.TDS_PCT == '') {
      currFormInput.TDS_Amt = '';
      currFormInput.TCS_Amt = '';
      currFormInput.TDS_PCT = '';
      currFormInput.TCS_PCT = '';
    }

    setFormInput({
      ...currFormInput,
      cgstTotal: currFormInput.cgstTotal,
      sgstTotal: currFormInput.sgstTotal,
      igstTotal: currFormInput.igstTotal,
      grandTotal: currFormInput.grandTotal,
      TDS_Amt: currFormInput.TDS_Amt,
      TCS_Amt: currFormInput.TCS_Amt,
    });
  };

  const childItemChange = (event, index) => {
    console.log(event);
    let chk = itemDuplicateCheck(event.value, index, 'IteamGRA-' + index + '-item');
    if (chk) {
      formInput.IteamGRA[index]['defaultItem'] = { label: event.label, value: event.value, measure: event.measure };
      formInput.IteamGRA[index]['item'] = event.value;
      formInput.IteamGRA[index]['itemStock'] = event.available > 0 ? event.available : 0;
      setFormInput({ ...formInput, IteamGRA: formInput.IteamGRA });
      pullDownReset('IteamGRA-' + index + '-item');
      let container = document.querySelector('.pi-input-container');
      let err = container.querySelector('.error-message');
      if (err == null) {
        setBtnDisable(false);
      }
    }
  };

  const itemDuplicateCheck = (id, index, element) => {
    let el = document.getElementById(element);
    let find = formInput.IteamGRA.find((x, key) => {
      return x.item == id && key != index;
    });
    if (find == undefined) {
      return true;
    } else {
      setBtnDisable(true);
      appendErrorMsg(el, undefined, 'Duplicate Item');
      return false;
    }
  };

  const removeItems = (index) => {
    let operations = formInput.IteamGRA.filter((value, key) => {
      return key !== index;
    });
    setFormInput({ ...formInput, IteamGRA: operations });
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setPickId('');
    setDefaultSupplier([]);
    resetRequired();
    setDefaultDecideGST({ label: 'CGST/SGST', value: true });
    setFormInput({ ...initialFormInput });
    setoldItems([]);
    setShowErr('');
    setBtnDisable(false);
  };

  const handleModalOpen = (id) => {
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    showStoreItemUtil(id);
  };

  const handleModalClose = (event) => {
    setIsModalOpen(false);
    document.getElementsByTagName('body')[0].style.position = 'unset';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const modalWrapperClick = (e) => {
    if (e.target.classList.contains('popup-parent')) {
      handleModalClose();
    }
  };

  const popup = isModalOpen ? (
    <div className="popup-parent" onClick={(e) => modalWrapperClick(e)}>
      <div className="popup-wrapper">
        <div
          className="pop-up-close"
          onClick={() => {
            handleModalClose();
          }}
        >
          x
        </div>
        <div className="pop-content">
          {showData.attributes.IteamGRA && (
            <div className="div-part1 operation-list">
              <h5>Items</h5>
              <div className="div-part1 operation-part">
                <table className="stripped bordered hover pioperations-table">
                  <thead>
                    <tr>
                      <th width="200px">Item</th>
                      <th width="60px">Stock</th>
                      <th width="60px">PO</th>
                      <th width="60px">Ordered</th>
                      <th width="60px">Accepted</th>
                      <th width="60px">Rejected</th>
                      <th width="60px">Rate</th>
                      <th width="60px">Tax %</th>
                      {showData.attributes.sgstTotal > 0 && (
                        <>
                          <th width="60px">CGST</th>
                          <th width="60px">SGST</th>
                        </>
                      )}
                      {showData.attributes.igstTotal > 0 && <th width="60px">IGST</th>}
                      <th width="60px">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {showData.attributes.IteamGRA &&
                      showData.attributes.IteamGRA.map((row, i) => (
                        <tr key={'items-' + i}>
                          <td>{row.item.data.attributes.itemCode + ' - ' + row.item.data.attributes.itemName}</td>
                          <td>{row.item.data.attributes.available}</td>
                          <td>{row.po}</td>
                          <td>{row.orderedQty}</td>
                          <td>{row.acceptedQty}</td>
                          <td>{row.rejectedQty}</td>
                          <td>{row.ratePerQty}</td>
                          <td>{row.tax}</td>
                          {showData.attributes.sgstTotal > 0 && (
                            <>
                              <td>{row.cgst}</td>
                              <td>{row.sgst}</td>
                            </>
                          )}
                          {showData.attributes.igstTotal > 0 && <td>{row.igst}</td>}
                          <td>{row.total}</td>
                        </tr>
                      ))}
                    <tr>
                      <td colSpan="8" className="pull-right">
                        Total
                      </td>
                      {showData.attributes.sgstTotal > 0 && (
                        <>
                          <td>{showData.attributes.sgstTotal}</td>
                          <td>{showData.attributes.cgstTotal}</td>
                        </>
                      )}
                      {showData.attributes.igstTotal > 0 && (
                        <>
                          <td>{showData.attributes.igstTotal}</td>
                        </>
                      )}
                      <td>{showData.attributes.grandTotal}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;

  const getApi = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          buildQuery(
            'store-item-gras?populate[0]=supplier&sort[0]=id:desc&pagination[page]=' +
              page +
              '&pagination[pageSize]=20',
            tableFilter,
            filterColumns,
          ),
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setTableData(response.data.data);
        setTotalPage(response.data.meta.pagination.pageCount);
        hideLoader();
        if (page == 1 && totalPage == 0) {
          getSuppliers();
          getItems();
        }
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSuppliers = () => {
    showLoader();

    axios
      .get(baseUri() + 'users?filters[userType][$eq]=3&fields[0]=userCode&fields[1]=name', {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setSupplierOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getItems = () => {
    axios
      .get(baseUri() + 'items?fields[0]=itemCode&fields[1]=itemName&fields[2]=available&fields[3]=measure', {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];

        response.data.data.forEach(function (item, index) {
          options.push({
            label: item.attributes.itemCode + ' - ' + item.attributes.itemName,
            value: item.id,
            available: item.attributes.available,
            measure: item.attributes.measure,
          });
        });
        setItemOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const checkInvoiceData = () => {
    const postData = {
      data: {
        ...formInput,
      },
    };
    postApi();
    // if (postData.data.graItemNum && postData.data.invoiceNumber !== 'TBE' && postData.data.invoiceNumber) {
    //   axios({
    //     method: 'GET',
    //     url: `${baseUri()}store-item-gra/chk-dup-gra-itnum?itemNum=${postData.data.graItemNum}&invoiceNum=${
    //       postData.data.invoiceNumber
    //     }`,
    //     headers: {
    //       authorization: 'Bearer ' + authState.authData,
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json',
    //     },
    //   })
    //     .then((response) => {
    //       let result = response.data.data.result;
    //       if (result.length !== 0) {
    //         let invoiceValid = result[0].invoice_number;
    //         toast.dismiss();
    //         toast.error(`Duplicate Invoice Num: ${invoiceValid}`);
    //         return false;
    //       } else {
    //         postApi();
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       return false;
    //     });
    // } else {
    //   postApi();
    // }
  };

  const postApi = () => {
    const postData = {
      data: {
        ...formInput,
      },
    };
    let validate = requireCheck(postData);
    let invoiceValid = false;
    if (postData.data.graItemNum && postData.data.invoiceNumber === 'TBE') {
      let invoiceNumber = document.querySelector('.invoiceNumber');
      appendErrorMsg(invoiceNumber, invoiceNumber?.parentElement, 'This field is required');
      return false;
    }
    if (!validate || postData.data.IteamGRA.length == 0) {
      if (postData.data.IteamGRA.length == 0) {
        alert('Kindly Add a Item');
      }
      return false;
    }
    if (+postData.data.accountVouchNum > 99999) {
      let accountVouchNum = document.querySelector('.accountVouchNum');
      appendErrorMsg(accountVouchNum, accountVouchNum?.parentElement, 'Value greater than 99999');
      return false;
    }
    if (!Number.isInteger(+postData.data.accountVouchNum) || +postData.data.accountVouchNum < 0) {
      let accountVouchNum = document.querySelector('.accountVouchNum');
      appendErrorMsg(accountVouchNum, accountVouchNum?.parentElement, 'Value must be a positive integer');
      return false;
    }
    for (const key in postData['data']) {
      if (
        postData['data'][key] == '' ||
        key == 'graItemNumOptions' ||
        key == 'graItemGroupOptions' ||
        key == 'graItemCategoryOptions'
      ) {
        delete postData['data'][key];
      }
    }

    if (!postData['data']['TCS_PCT']) {
      postData['data']['TCS_PCT'] = 0;
      postData['data']['TCS_Amt'] = 0;
    }

    if (!postData['data']['TDS_PCT']) {
      postData['data']['TDS_PCT'] = 0;
      postData['data']['TDS_Amt'] = 0;
    }

    let resultItems = calculateItemQty();
    if (!resultItems.valid) {
      setShowErr('Seems you are trying to delete item stock which is already used');
      return false;
    }
    //return false;
    let uriPre = baseUri() + 'store-item-gras';
    let method = 'post';
    if (pickId) {
      uriPre = baseUri() + 'store-item-gras' + '/' + pickId;
      method = 'put';
    }
    axios({
      method: method,
      url: uriPre,
      data: postData,
      headers: {
        authorization: 'Bearer ' + authState.authData,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        let len = resultItems.items.length - 1;
        let currItemsObj = [];
        let oldItemsObj = [];
        formInput.IteamGRA.forEach((item, idx) => {
          currItemsObj.push({
            id: item.item,
            qty: +(+item.acceptedQty).toFixed(2),
          });
        });
        oldItems.forEach((item, idx) => {
          oldItemsObj.push({
            id: item.item,
            qty: +(+item.current).toFixed(2),
          });
        });
        clearFormData();
        axios
          .post(
            baseUri() + 'store-item-gra/stock-update',
            {
              data: {
                currItems: currItemsObj,
                oldItems: oldItemsObj,
              },
            },
            {
              headers: {
                authorization: 'Bearer ' + authState.authData,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            },
          )
          .then((res) => {
            refreshPage();
          });
      })
      .catch((error) => {
        if (error.response.status == 400) {
          let el = document.getElementById('graItemNum');
          appendErrorMsg(el, false, 'Gra Item number should be unique');
          hideLoader();
        }
      });
  };

  const calculateItemQty = (remove = false) => {
    let items = [];
    let isValid = true;
    if (remove) {
      oldItems.forEach((x) => {
        let tot = Number(x.available) - Number(x.current);
        items.push({ total: tot, item: x.item });
        if (tot < 0) {
          isValid = false;
        }
      });
    } else {
      formInput.IteamGRA.forEach((item) => {
        let inStock = itemOptions.find((x) => {
          return x.value == item.item;
        });
        let current = 0;
        if (oldItems.length) {
          let record = oldItems.find((x) => {
            return x.item == item.item;
          });
          if (record != undefined) {
            current = Number(record.current);
          }
        }
        let tot = Number(inStock.available) - current + Number(item.acceptedQty);
        if (tot < 0) {
          isValid = false;
        }
        items.push({ total: tot, item: item.item });
      });
      oldItems.forEach((x) => {
        let chk = items.find((item) => {
          return item.item == x.item;
        });
        if (chk == undefined) {
          let tot = Number(x.available) - Number(x.current);
          items.push({ total: tot, item: x.item });
          if (tot < 0) {
            isValid = false;
          }
        }
      });
    }
    return { items: items, valid: isValid };
  };

  const editStoreItemUtil = (id, remove = false) => {
    showLoader();
    clearFormData();
    axios
      .get(
        baseUri() +
          `store-item-gras/${id}?fields[0]=cgstTotal&fields[1]=graDate&fields[2]=grandTotal&fields[3]=igstTotal&fields[4]=invoiceDate&fields[5]=invoiceNumber&fields[6]=sgstTotal&fields[7]=transportName&fields[8]=vechileNumber&fields[9]=InPassDate&fields[10]=InPassNumber&fields[11]=TCS_Amt&fields[12]=TDS_Amt&fields[13]=TCS_PCT&fields[14]=graItemCat&fields[15]=graItemGrp&fields[16]=graItemNum&fields[17]=remarks&fields[18]=TDS_PCT&populate[supplier][fields][0]=userCode&populate[supplier][fields][2]=name&populate[IteamGRA][fields][0]=acceptedQty&populate[IteamGRA][fields][0]=cgst&populate[IteamGRA][fields][0]=igst&populate[IteamGRA][fields][0]=orderedQty&populate[IteamGRA][fields][0]=po&populate[IteamGRA][fields][0]=ratePerQty&populate[IteamGRA][fields][0]=rejectedQty&populate[IteamGRA][fields][0]=sgst&populate[IteamGRA][fields][0]=tax&populate[IteamGRA][fields][0]=total&populate[IteamGRA][populate][item][fields][0]=itemCode&populate[IteamGRA][populate][item][fields][1]=itemName&populate[IteamGRA][populate][item][fields][2]=available&populate[IteamGRA][populate][item][fields][3]=measure&fields[19]=account_vouch_num&fields[20]=account_date`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setSave(pageAccess.edit === 1);
        response = response.data.data;
        setPickId(response.id);
        const aa = Object.assign({}, response.attributes);
        aa.TCS_Amt = response.attributes.TCS_Amt;
        aa.TCS_PCT = response.attributes.TCS_PCT;
        aa.TDS_Amt = response.attributes.TDS_Amt;
        aa.TDS_PCT = response.attributes.TDS_PCT;
        aa.cgstTotal = response.attributes.cgstTotal;
        aa.sgstTotal = response.attributes.sgstTotal;
        aa.grandTotal = response.attributes.grandTotal;
        aa.supplier = response.attributes.supplier.data.id;
        aa.decideGST = response.attributes.igstTotal > 0 ? false : true;
        aa.invoiceDate = dateFormat(aa.invoiceDate);
        aa.InPassDate = dateFormat(aa.InPassDate);
        aa.InPassNumber = aa.InPassNumber;
        aa.graItemCategoryOptions = graItemCategoyOptions;
        aa.graItemGroupOptions = graItemGroupOptions;
        aa.accountVouchNum = response.attributes.accountVouchNum || '';
        aa.accountDate = response.attributes.accountDate || '';
        if (aa.TCS_PCT) {
          aa.tdsPctDisabled = true;
        } else if (aa.TDS_PCT) {
          aa.tcsPctDisabled = true;
        }
        if (aa.graItemCat) {
          let filteredCat = graItemCategoyOptions.filter((data) => {
            return data.value == aa.graItemCat;
          });
          aa.defaultGraItemCat = { label: `${filteredCat[0].label}`, value: filteredCat[0].value };
          createGraItemOptions(filteredCat[0], aa.graItemNum, aa);
        } else {
          aa.defaultGraItemCat = [];
          aa.graItemNumDefault = [];
        }
        if (aa.graItemGrp >= 0 && aa.graItemGrp <= 9) {
          let filteredgrp = graItemGroupOptions.filter((data) => {
            return data.value == aa.graItemGrp;
          });
          aa.graItemGrpDefault = { label: `${filteredgrp[0]?.label}`, value: filteredgrp[0]?.value };
        } else {
          aa.graItemGrpDefault = [];
        }
        setDefaultDecideGST({ label: aa?.decideGST ? 'CGST/SGST' : 'IGST', value: aa?.decideGST });
        aa.IteamGRA.map((itemInfo, index) => {
          aa.IteamGRA[index].defaultItem = {
            label:
              itemInfo['item']['data']['attributes']['itemCode'] +
              ' - ' +
              itemInfo['item']['data']['attributes']['itemName'],
            value: itemInfo['item']['data']['id'],
            measure: itemInfo['item']['data']['attributes']['measure'],
          };
          aa.IteamGRA[index].itemStock = itemInfo['item']['data']['attributes']['available'];
          aa.IteamGRA[index].item = itemInfo['item']['data']['id'];
        });
        if (!remove) {
          setDefaultSupplier({
            label:
              response.attributes.supplier.data.attributes.userCode +
              ' - ' +
              response.attributes.supplier.data.attributes.name,
            value: response.attributes.supplier.data.id,
          });
          setFormInput(aa);
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }

        let prevData = [];
        aa.IteamGRA.forEach((item) => {
          var stock = itemOptions.find((x) => {
            return item.item == x.value;
          });
          prevData.push({ item: item.item, available: stock.available, current: item.acceptedQty });
        });
        setoldItems(prevData);
        hideLoader();
        if (remove) {
          setTimeout(() => {
            document.getElementById('del' + id).click();
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error);
        //return  error;
      });
  };

  const showStoreItemUtil = (id) => {
    showLoader();
    axios
      .get(baseUri() + `store-item-gras/${id}?populate[0]=supplier&populate[1]=IteamGRA&populate[2]=IteamGRA.item`, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setShowData(response.data.data);
        setIsModalOpen(true);
        hideLoader();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deleteStoreItemUtil = (id) => {
    let resultItems = calculateItemQty(true);
    if (!resultItems.valid) {
      alert('You cannot delete items stocks which is already used');
      return false;
    }
    const removeGroup = window.confirm('Would you like to delete this record?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'store-item-gras/' + id, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
          let oldItemsObj = [];
          oldItems.forEach((item, idx) => {
            oldItemsObj.push({
              id: item.item,
              qty: +(+item.current).toFixed(2),
            });
          });
          axios
            .post(
              baseUri() + 'store-item-gra/stock-update',
              {
                data: {
                  currItems: [],
                  oldItems: oldItemsObj,
                },
              },
              {
                headers: {
                  authorization: 'Bearer ' + authState.authData,
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              },
            )
            .then((res) => {
              refreshPage();
            });
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div className="action-container">
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            id={'editData' + row.id}
            onClick={() => {
              editStoreItemUtil(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>

          <ActionBtn
            data-tag="allowRowEvents"
            className="btn-rad ml-10"
            onClick={() => {
              handleModalOpen(row.id);
            }}
          >
            {' '}
            <EvaIcon name="eye" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                editStoreItemUtil(row.id, true);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtndel-color hide"
            id={'del' + row.id}
            onClick={() => {
              deleteStoreItemUtil(row.id);
            }}
          ></ActionBtn>
        </div>
      ),
    },
    {
      name: 'ID',
      selector: (row) => `${row.id}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'GRA Date',
      selector: (row) => `${dateFormat(row.attributes.graDate)}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Supplier',
      selector: (row) =>
        `${
          row.attributes.supplier.data
            ? row.attributes.supplier.data.attributes.userCode + ' - ' + row.attributes.supplier.data.attributes.name
            : ''
        }`,
      sortable: true,
      maxWidth: '25%',
    },
    {
      name: 'Invoice No.',
      selector: (row) => `${row.attributes.invoiceNumber}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Invoice Date',
      selector: (row) => `${dateFormat(row.attributes.invoiceDate)}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Transport Name',
      selector: (row) => `${row.attributes.transportName}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Vehicle No.',
      selector: (row) => `${row.attributes.vechileNumber}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Account Vouch Num',
      selector: (row) => `${row.attributes.accountVouchNum}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Account Date',
      selector: (row) => `${row.attributes.accountDate}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Total',
      selector: (row) => `${row.attributes.grandTotal}`,
      sortable: true,
      maxWidth: '20%',
    },
  ];

  const filterColumns = [
    'id',
    'supplier.userCode',
    'supplier.name',
    'vechileNumber',
    'transportName',
    'invoiceDate',
    'invoiceNumber',
    'graDate',
    'grandTotal',
  ];

  return (
    <>
      {popup}
      <SEO title="Store Item GRA" />
      <Row>
        <Toaster />
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Store Item GRA</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>GRA Date </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="graDate"
                      id="graDate"
                      placeholder="graDate"
                      onChange={(e) => handleChange(e)}
                      value={formInput.graDate}
                      className="graDate require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Invoice Date </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="invoiceDate"
                      placeholder="Invoice Date"
                      onChange={(e) => handleChange(e)}
                      value={formInput.invoiceDate}
                      className="invoiceDate require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Invoice No. </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="invoiceNumber"
                      id="invoiceNumber"
                      placeholder="Invoice No."
                      onChange={(e) => handleChange(e)}
                      value={formInput.invoiceNumber}
                      className={`invoiceNumber ${
                        (formInput.invoiceNumber !== 'TBE' && formInput.invoiceNumber) || formInput.graItemNum
                          ? 'require'
                          : ''
                      }`}
                    />
                  </Input>
                </Col>

                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Tax Mode</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => decideGSTChange(e)}
                    options={decideGSTOptions}
                    id="decideGST"
                    name="decideGST"
                    placeholder="Select Tax"
                    value={defaultDecideGST}
                    className="pull-down taxmode"
                  />
                </Col>

                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>InPass Number </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="InPassNumber"
                      id="InPassNumber"
                      maxLength={50}
                      placeholder="InPass Number"
                      onChange={(e) => handleChange(e)}
                      value={formInput.InPassNumber}
                      className="InPassNumber require"
                    />
                  </Input>
                </Col>

                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>InPass Date </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="InPassDate"
                      id="InPassDate"
                      placeholder="InPass Date"
                      onChange={(e) => handleChange(e)}
                      value={formInput.InPassDate}
                      className="InPassDate require"
                    />
                  </Input>
                </Col>

                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>GRA Item Category</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => graItemCategoryChange(e)}
                    options={formInput.graItemCategoryOptions}
                    id="graItemCat"
                    name="graItemCat"
                    placeholder="Select graItemCat"
                    value={formInput.defaultGraItemCat}
                    className="pull-down require"
                  />
                </Col>

                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>GRA Item Num</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => graItemNumChange(e)}
                    options={formInput.graItemNumOptions}
                    id="graItemNum"
                    name="graItemNum"
                    placeholder="Select graItemNum"
                    value={formInput.graItemNumDefault}
                    className={`pull-down ${formInput.invoiceNumber !== 'TBE' ? 'require' : ''}`}
                  />
                </Col>

                <Col breakPoint={{ xs: 12, md: 6 }}>
                  <label>GRA Item Group</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => graItemGroupChange(e)}
                    options={formInput.graItemGroupOptions}
                    id="graItemGrp"
                    name="graItemGrp"
                    placeholder="Select graItemGrp"
                    value={formInput.graItemGrpDefault}
                    className="pull-down require"
                  />
                </Col>

                <Col breakPoint={{ xs: 12, md: 6 }}>
                  <label>Select Supplier</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => supplierChange(e)}
                    options={supplierOptions}
                    id="supplier"
                    name="supplier"
                    placeholder="Select Supplier"
                    value={defaultSupplier}
                    className="pull-down supplier require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Transport Name </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="transportName"
                      placeholder="Transport Name"
                      onChange={(e) => handleChange(e)}
                      value={formInput.transportName}
                      className="transportName require"
                    />
                  </Input>
                  <Row>
                    <Col>
                      <label>Account Vouch Num</label>
                      <Input fullWidth size="Small">
                        <input
                          type="number"
                          name="accountVouchNum"
                          min={0}
                          max={99999}
                          placeholder="Account Vouch Num"
                          onChange={(e) => handleChange(e)}
                          value={formInput.accountVouchNum}
                          className="accountVouchNum"
                        />
                      </Input>
                    </Col>
                  </Row>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Vehicle No </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="vechileNumber"
                      placeholder="Vechile No."
                      onChange={(e) => handleChange(e)}
                      value={formInput.vechileNumber}
                      className="vechileNumber require"
                    />
                  </Input>
                  <Row>
                    <Col>
                      <label>Accnt Date</label>
                      <Input fullWidth size="Small">
                        <input
                          type="date"
                          name="accountDate"
                          placeholder="Accnt Date"
                          onChange={(e) => handleChange(e)}
                          value={formInput.accountDate}
                          className={`accntDate ${formInput.graItemNum ? 'require' : ''}`}
                        />
                      </Input>
                    </Col>
                  </Row>
                </Col>

                <Col breakPoint={{ xs: 12, md: 6 }}>
                  <label>Remarks </label>
                  <Input fullWidth size="Small">
                    <textarea
                      type="text"
                      name="remarks"
                      placeholder="Remarks"
                      onChange={(e) => handleChange(e)}
                      value={formInput.remarks}
                      className="remarks"
                    />
                  </Input>
                </Col>

                {formInput.IteamGRA.length > 0 && (
                  <Col breakPoint={{ xs: 12 }} className="supplier-util-title">
                    Items
                  </Col>
                )}
                {formInput.IteamGRA.length > 0 && (
                  <Col breakPoint={{ xs: 12, md: 12 }} className="pi-input-container m-b0">
                    {formInput.IteamGRA.map((item, key) => {
                      return (
                        <div key={key}>
                          <Row key={'items-' + key} className="itemGRA-inputs">
                            <Col breakPoint={{ xs: 12, md: 3 }}>
                              {key == 0 && <label>Item</label>}
                              <SelectField
                                fullWidth
                                onChange={(e) => childItemChange(e, key)}
                                options={itemOptions}
                                value={item.defaultItem}
                                id={'IteamGRA-' + key + '-item'}
                                placeholder="Select Item"
                                className="pull-down require"
                              />
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 0.8 }}>
                              {key == 0 && <label>UOM</label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="text"
                                  placeholder="UOM"
                                  readOnly
                                  value={measureArray.filter((a) => a.value === item.defaultItem.measure)[0]?.label}
                                  className="itemStock require"
                                />
                              </Input>
                            </Col>

                            <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                              {key == 0 && <label>Stock </label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="text"
                                  placeholder="Stock"
                                  name="itemStock"
                                  disabled="disabled"
                                  value={item.itemStock}
                                  className="itemStock require"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 0.8 }} className="p-l0">
                              {key == 0 && <label>PO No. </label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="text"
                                  placeholder="PO"
                                  name="po"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.po}
                                  className="po require"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 0.8 }} className="p-l0">
                              {key == 0 && <label>Ordered </label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="text"
                                  placeholder="Qty"
                                  name="orderedQty"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.orderedQty}
                                  className="orderedQty require"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 0.8 }} className="p-l0">
                              {key == 0 && <label>Accepted </label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="text"
                                  placeholder="Qty"
                                  name="acceptedQty"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.acceptedQty}
                                  className="acceptedQty require"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 1 }} className="hide">
                              {key == 0 && <label>Rejected</label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="text"
                                  placeholder="Qty"
                                  name="rejectedQty"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.rejectedQty}
                                  className="rejectedQty"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 0.8 }} className="p-l0">
                              {key == 0 && <label>Rate </label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="text"
                                  placeholder="Rate"
                                  name="ratePerQty"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.ratePerQty}
                                  className="ratePerQty require"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 0.8 }} className="p-l0">
                              {key == 0 && <label>Tax % </label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="text"
                                  placeholder="%"
                                  name="tax"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.tax}
                                  className="tax require"
                                />
                              </Input>
                            </Col>

                            {formInput.decideGST ? (
                              <>
                                <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                                  {key == 0 && <label>CGST </label>}
                                  <Input fullWidth size="Small">
                                    <input
                                      type="text"
                                      placeholder="CGST"
                                      name="cgst"
                                      onChange={(e) => itemInputChange(e, key)}
                                      value={item.cgst}
                                      className="cgst"
                                    />
                                  </Input>
                                </Col>
                                <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                                  {key == 0 && <label>SGST </label>}
                                  <Input fullWidth size="Small">
                                    <input
                                      type="text"
                                      placeholder="SGST"
                                      name="sgst"
                                      onChange={(e) => itemInputChange(e, key)}
                                      value={item.sgst}
                                      className="sgst"
                                    />
                                  </Input>
                                </Col>
                              </>
                            ) : (
                              <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                                {key == 0 && <label>IGST </label>}
                                <Input fullWidth size="Small">
                                  <input
                                    type="text"
                                    placeholder="IGST"
                                    name="igst"
                                    onChange={(e) => itemInputChange(e, key)}
                                    value={item.igst}
                                    className="igst"
                                  />
                                </Input>
                              </Col>
                            )}
                            <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                              {key == 0 && <label>Amount </label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="text"
                                  placeholder="Amt"
                                  name="total"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.total}
                                  className="total"
                                />
                              </Input>
                            </Col>
                            <div
                              onClick={() => removeItems(key)}
                              className={key == 0 ? 'div-btn-abs top20' : 'div-btn-abs top5'}
                            >
                              {key == 0 && <label className="div-full"></label>}
                              <label className="delBtn piOperationDelete div-full">
                                <span>-</span>
                              </label>
                            </div>
                          </Row>
                        </div>
                      );
                    })}
                  </Col>
                )}
                {formInput.IteamGRA.length > 0 && (
                  <Col breakPoint={{ xs: 12, md: 12 }}>
                    <Row>
                      <Col breakPoint={{ xs: 12, md: 5 }} className="adj-pad"></Col>
                      <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                        <label>TCS % </label>
                        <Input fullWidth size="Small">
                          <input
                            disabled={formInput.tcsPctDisabled}
                            type="text"
                            placeholder="TCS %"
                            name="TCS_PCT"
                            onChange={(e) => handleChange(e)}
                            value={formInput.TCS_PCT}
                            className="TCS_PCT"
                          />
                        </Input>
                      </Col>
                      <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                        <label>TDS % </label>
                        <Input fullWidth size="Small">
                          <input
                            disabled={formInput.tdsPctDisabled}
                            type="text"
                            placeholder="TDS %"
                            name="TDS_PCT"
                            onChange={(e) => handleChange(e)}
                            value={formInput.TDS_PCT}
                            className="TDS_PCT"
                          />
                        </Input>
                      </Col>
                      <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                        <label>TCS Amt </label>
                        <Input fullWidth size="Small">
                          <input
                            type="text"
                            placeholder="TCS Amt"
                            id="TCS_Amt"
                            name="TCS_Amt"
                            onChange={(e) => handleChange(e)}
                            value={formInput.TCS_Amt}
                            className="TCS_Amt"
                          />
                        </Input>
                      </Col>
                      <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                        <label>TDS Amt </label>
                        <Input fullWidth size="Small">
                          <input
                            type="text"
                            placeholder="TCS Amt"
                            id="TDS_Amt"
                            name="TDS_Amt"
                            onChange={(e) => handleChange(e)}
                            value={formInput.TDS_Amt}
                            className="TDS_Amt"
                          />
                        </Input>
                      </Col>
                      {formInput.decideGST ? (
                        <>
                          <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                            <label>CGST </label>
                            <Input fullWidth size="Small">
                              <input
                                type="text"
                                placeholder="CGST"
                                name="cgstTotal"
                                onChange={(e) => handleChange(e)}
                                value={formInput.cgstTotal}
                                className="cgstTotal"
                              />
                            </Input>
                          </Col>
                          <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                            <label>SGST </label>
                            <Input fullWidth size="Small">
                              <input
                                type="text"
                                placeholder="SGST"
                                name="sgstTotal"
                                onChange={(e) => handleChange(e)}
                                value={formInput.sgstTotal}
                                className="sgstTotal"
                              />
                            </Input>
                          </Col>
                        </>
                      ) : (
                        <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                          <label>IGST </label>
                          <Input fullWidth size="Small">
                            <input
                              type="text"
                              placeholder="IGST"
                              name="igstTotal"
                              onChange={(e) => handleChange(e)}
                              value={formInput.igstTotal}
                              className="igstTotal"
                            />
                          </Input>
                        </Col>
                      )}
                      <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                        <label>Total </label>
                        <Input fullWidth size="Small">
                          <input
                            type="text"
                            placeholder="Total"
                            name="grandTotal"
                            onChange={(e) => handleChange(e)}
                            value={formInput.grandTotal}
                            className="grandTotal"
                          />
                        </Input>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col breakPoint={{ xs: 12, md: 12 }} className="PiAddBtnContainer">
                  <Button onClick={() => AddItem()} className="addPibtn">
                    Add Item <EvaIcon name="plus" className="plusIcon" />
                  </Button>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="mt20">
                  {showErr && <p className="red">{showErr}</p>}
                  <Button
                    disabled={btnDisable || !isSave}
                    onClick={checkInvoiceData}
                    status="Success"
                    type="button"
                    className="btnrad"
                    shape=""
                    halfWidth
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Store Item GRA</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => setTableFilter(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <Button
                onClick={() => {
                  if (page == 1) {
                    getApi();
                  } else {
                    setPage(1);
                  }
                }}
              >
                Filter
              </Button>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
              />
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePagination}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default StoreItemGra;
